<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <el-form
            ref="search"
            :inline="true"
            class="searchBoxForm"
            :model="paramsData"
          >
            <el-form-item label="时间段" prop="equipmentStatus">
              <el-date-picker
                
                size="mini"
                style="width: 250px"
                v-model="DataPicker"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                value-format="yyyy-MM-dd"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              <!-- @change="search1" -->
              </el-date-picker>
            </el-form-item>
            <el-form-item label="来源" prop="equipmentStatus" style="margin-left:20px">
              <el-select
                clearable
                size="mini"
                style="width: 170px"
                v-model="pageParams.source"
                placeholder="请选择来源"
                
              >
              <!-- @change="searchList" -->
                <el-option
                  v-for="item in selectDownArray"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="equipmentStatus" style="margin-left:20px">
              <el-select
                clearable
                size="mini"
                style="width: 170px"
                v-model="pageParams.solveStatus"
                placeholder="请选择状态"
                
              >
              <!-- @change="searchList" -->
                <el-option
                  v-for="item in excutorDownArray"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="记录人" prop="equipmentStatus"  style="margin-left:20px">
              <el-input
                clearable
                size="mini"
                placeholder="请输入记录人"
                v-model="pageParams.createUserName"
                style="width: 170px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="解决人" prop="equipmentStatus"  style="margin-left:20px">
              <el-input
                clearable
                size="mini"
                placeholder="请输入解决人"
                v-model="pageParams.solveUserName"
                style="width: 170px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                placeholder="请输入关键字"
                size="mini"
                v-model="pageParams.keyword"
              >
                <el-select
                  placeholder="请选择"
                  v-model="pageParams.keyType"
                  slot="prepend"
                >
                  <el-option
                    v-for="item in searchBar"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="" style="margin-left:20px">
              <el-button size="mini" type="primary" @click="searchList" style=""
                >查询</el-button
              >
              <el-button
                size="mini"
                type="warning"
                style="margin-left: 10px"
                @click="resize"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table
        ref="tableData"
        :data="tableData"
        style="width: 100%; margin: 15px 0"
        border
        class="table-height-in"
        :stripe="true"
        height="calc(100vh - 385px)"
        :header-cell-style="{
          color: '#333',
          fontFamily: 'MicrosoftYaHeiUI',
          fontSize: '14px',
          fontWeight: 900,
          textAlign: 'center',
          background: '#f8f8f9',
        }"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="left"
          width="50"
        >
        </el-table-column>
        <el-table-column
          prop="residentTypeName"
          label="用户类型"
          align="left"
          show-overflow-tooltip
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="residentName"
          label="关联用户"
          align="left"
          width="120px"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="contactName"
          label="联系人"
          align="left"
          width="120px"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="contactMobile"
          label="联系电话"
          align="left"
          show-overflow-tooltip
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="contactMobileEx"
          label="备用联系电话"
          align="left"
          show-overflow-tooltip
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          align="left"
          show-overflow-tooltip
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="sourceName"
          label="来源"
          align="left"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="createBy"
          label="记录人"
          align="left"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="left"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="solveUserName"
          label="解决人"
          align="left"
          width="120px"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="solveRemark"
          label="说明"
          align="left"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="solveTime"
          label="解决时间"
          align="left"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="solveStatusName"
          label="状态"
          align="left"
          show-overflow-tooltip
          fixed="right"
        >
          <template slot-scope="{ row }">
            <div
              :style="{ color: row.solveStatusName == '未解决' ? 'red' : '' }"
            >
              {{ row.solveStatusName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="150" fixed="right">
          <template slot-scope="{ row }">
            <div class="operateTool">
              <el-button type="primary" size="mini" 
              v-if="$anthButtons.getButtonAuth('updataconsult')"
              @click="IsOpenFun(4, row)"
                >编辑</el-button
              >
              <el-button
                type="primary"
                plain
                v-if="row.solveStatusName == '未解决' && $anthButtons.getButtonAuth('solveconsult')"
                @click="handle(row)"
                size="mini"
                >解决</el-button
              >
              <!-- <el-button type="primary" plain v-if="row.taskGenerateStatus<2"  @click="addItem(row)" size="mini"
            >编辑</el-button>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
   
    <!-- 解决 -->
    <el-dialog
      :title="titles"
      :visible.sync="redeployVisible"
      width="460px"
    >
      <el-form
        :model="DeviceData"
        ref="DeviceData"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="说明" >
          <el-input
            v-model="DeviceData.solveRemark"
            type="textarea"
            style="width: 338px"
            placeholder="请填写说明"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="redeployVisible = false"
          >取 消</el-button
        >
        <el-button size="small" @click="submitRedeploy" type="primary"
          >确 认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  templateGeneralStatusToggle,
} from "@/RequestPort/dict/cycle";
import { consultOrderPage,userPageList,unUserPageList,solve } from "@/RequestPort/serviceOrders";
import pageMixins from "@/utils/pageMixins";
export default {
  name: "Sample",
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
        taskType: 2,
        keyWordValue: "",
        keyType: 1,
      }, //  分页查询参数
      tableData: [], //  表格数据
      total: 0,
      // 使用情况
      useCondition: [
        { label: "未使用", value: 1 },
        { label: "被使用", value: 2 },
      ],
      // 启用状态
      enableStart: [
        { label: "禁用", value: 0 },
        { label: "启用", value: 1 },
      ],
      // 来源
      selectDownArray: [
        { label: "电话", value: 1 },
        { label: "大厅", value: 2 },
      ],
      // 解决状态
      excutorDownArray: [
        { label: "未解决", value: 1 },
        { label: "已解决", value: 2 },
      ],
      // 查询条件
      paramsData: {},
      rules: {
      },
      // 关键字条件
      searchBar: [
        { label: "联系人", id: 1, name: "residentName" },
        { label: "联系电话", id: 2, name: "contactName" },
        { label: "备注", id: 3, name: "address" },
      ],
      // 解决
      redeployVisible: false,
      // 转派人员
      excutorSpecialArray: [],
      // 解决说明
      DeviceData: {
      },
      titles: "",
      // 时间段
      DataPicker: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * 解决
     */
    handle(row) {
      this.titles = "解决";
      this.DeviceData = {}
      this.DeviceData.id = row.id
      this.redeployVisible = !this.redeployVisible;
    },
    /**
     * 重置
     */
    resize() {
      this.pageParams = {
        current: 1,
        size: 100,
        keyType: 1,
      };
      this.DataPicker = [];
      this.getList(1);
    },
    /**
     * 解决确认
     */
    submitRedeploy() {
      this.$refs.DeviceData.validate((valid) => {
        if (valid) {
          solve(this.DeviceData).then((res) => {
            this.$message.success(res.data);
            this.getList(1);
            this.redeployVisible = false;
          });
        }
      });
    },
    /**
     * 查询
     */
    search1() {
      if (this.DataPicker && this.DataPicker.length > 0) {
        this.pageParams.startTime = this.DataPicker[0];
        this.pageParams.endTime = this.DataPicker[1];
      } else {
        delete this.pageParams.startTime;
        delete this.pageParams.endTime;
      }
      this.pageParams.current = 1;
      this.getList();
    },
    /**
     * 备注弹窗编辑按钮
     */
    IsOpenFun(e, row) {
      this.$router.push({ path: '/home/repairManage/options/collecAdd', query: { first: 'fourth',id:row.id} })
    },
    /**
     * 获取列表
     */
    getList(val) {
      if (val) {
        this.pageParams.current = 1;
      }
      consultOrderPage(this.pageParams).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    /**
     * 搜索
     */
    searchList(val) {
      this.getList(1);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.pageParams.current = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageParams.current = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input-group__prepend {
  width: 67px;
}
::v-deep .el-form-item__content .el-input-group {
  vertical-align: initial;
}
.SearchBar {
  overflow: inherit;
  .el-form-item {
    margin-bottom: 0;
  }
}
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool {
  height: 25px;
}
//  .searchItem {
//       // margin-bottom: 1%;
//       //  margin-left: 1%;
//     }
// .info-item {
//   margin-bottom: 20px;
// }
// .label {
//   color: #909399;
// }
// .content-item {
//   color: #444444;
// }
// .SearchBar {
/* border: 1px solid #ecedf1; */
// border-radius: 5px;
// margin: 10px;
// padding-top: 1%;

// background-color: #ffffff;
// }
.all {
  // background-color:#f3f4f8;
  height: auto;
}
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  overflow: auto;
  height: calc(100vh - 227px);
}
.content-text {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.diaBtn {
  width: 100px;
  height: 40px;
  margin-bottom: 15px;
}
.el-divider--horizontal {
  width: 100%;
  margin: 20px auto 0px;
}
.el-pagination {
  margin: 15px 0px 15px;
}
</style>